//export const API_BRAINSCANOLOGY_URL    = "https://brainscanology-nodejs.azurewebsites.net";
//export const API_PRE_PROCESS_URL = 'https://shapegenie-preprocessing.greensky-ecdd5af8.eastus2.azurecontainerapps.io'

export const API_BRAINSCANOLOGY_URL    = "https://brainscanology-gateway.relevancelab.com";
export const API_PRE_PROCESS_URL  = "https://brainscanology-pre-processing.relevancelab.com";

export const API_FLASK_URL = 'https://brainscanology-processing.relevancelab.com'

//export const API_BRAINSCANOLOGY_URL = "http://localhost:8080";
//export const API_PRE_PROCESS_URL = "http://localhost:5005";
