export const SUBSCRIPTION_CONFIG = {
    plans: [
      {
        plan: "Basic Account",
        planPrice :"(Free)",
        subplan: "Best for Curious Evaluators",
        details: [
          {
            details1: "50 MB of Storage for Input Files",
            details2: "10 MB of Storage for Output Files",
            details3: "Analysis Limit: 250 images/30 days",
          },
        ],
        costing: "Free",
        disabled: false,
        subscriptionType: 1,
        totalInputLimit: '50 MB',
        totalOutputLimit: '10 MB',
        totalAnalysisLimit: '250',
        analysisDaysLimit:30,
        strikeText:"",
      },
      {
        plan: "Premium Account",
        planPrice :"$49.99",
        subplan: "Best for Research Labs, Biotech/Healthtech Institutions",
        details: [
          {
            details1: "500 MB of Storage for Input Files",
            details2: "100 MB of Storage for Output Files",
            details3: "Analysis Limit: 5,000 images/30 days",
            details4:  "Price: USD $49.99/month North America/EU/East Asia (Other Countries: Contact Us)",
            details5: "Dedicated Support",
            details6:  "Access to Advising: 2 hours/month"
          },
        ],
        costing: "$49.99",
        disabled: false,
        subscriptionType: 2,
        totalInputLimit: '500 MB',
        totalOutputLimit: '100 MB',
        totalAnalysisLimit: '5000',
        analysisDurationDays:30,
        strikeText:"$199",
      },
      {
        plan: "Professional Services",
        planPrice :"(Contact Us)",
        subplan: "For Personalized Insights and Dedicated Support",
        details: [
          {
            details1: "Consulting Service",
            details2: "1 GB of Storage for Input Files",
            details3: "1 GB of Storage for Output Files",
            details4: "Analysis Limit:Unlimited",
            details5: "For Price Details: Contact Us",
          },
        ],
        costing: "Contact Us",
        disabled: true,
        subscriptionType: 3,
        totalInputLimit: '1000 MB',
        totalOutputLimit: '1000 MB',
        totalAnalysisLimit: 'Unlimited',
        analysisDurationDays:30,
        strikeText:"",
      },
    ],
  };

export const FILE_SIZE_TYPES = {
  TYPE_KB:'KB',
  TYPE_MB:'MB',
  TYPE_GB:'GB',
  TYPE_TB:'TB',
}

export const PROCESS_STATUS = {
  IN_PROCESS: 'In-Process',
  SUCCESS:'Success',
  LIMIT_EXCEEDED:'Limit-Exceeded',
  FAILURE:'Failure'
}

export const EMAILS = {
  Notifications_id: "Notifications  <notifications@shapegenie.onmicrosoft.com>",
}