import React, {useEffect, useState } from "react";
import DocViewer, { PDFRenderer } from "react-doc-viewer";

const PDFPreview = ({ pdfURL }: any) => {
  const [docs, setDocs] = useState<Array<any>>([]);

  useEffect(() => {
    setDocs([{uri: pdfURL, fileType: "pdf"}])
  }, [pdfURL]);

  return (
    <>
      <DocViewer
        pluginRenderers={[PDFRenderer]}
        documents={docs}
        style={{ width: 650, height: 400 }}
      />
    </>
  );
};

export default PDFPreview;
