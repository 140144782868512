import React, { useEffect, useState } from "react";
import {
    Button,
    Modal,
    Form, Input, Card, Row, Col, Spin, Tooltip
} from "antd";

import { useDispatch, useSelector } from "react-redux";
import { IApplicationState } from "../../duck/reducers";
import { loadState } from "../../duck/reducers/commonReducer";
import { updateUserDetails } from "../../api/dashboardApi";
import openNotification from "../../common/Notifications";

const layout = {
    labelCol: {
      span: 20,
    },
    wrapperCol: {
      span: 20,
    },
  };

export default function FirstNameModal() {

    const { loginLoadState, error, userData } = useSelector(
        (state) => (state as IApplicationState).app
      );

    const { firstname, lastname } = useSelector(
        (state) => (state as IApplicationState).app.userData
      );

    const [loading, setLoading] = React.useState(false);
    const [isModalVisible, setModalVisible] = React.useState(firstname ?  false : true);

    const onDetailSubmit = async(values: any) => {
        values.username= userData.username;

        setLoading(true)
        await updateUserDetails(values)
        let successObj = {
            type: "success",
            message: "Updated Details Successfully.",
        };
        openNotification(successObj);
        setLoading(true)
        setModalVisible(false)
      };
    

    return (
        <Modal
            title="Profile Details"
            visible={isModalVisible}
            closable={false}
            maskClosable={false}
            footer={[
                <Button key="back" onClick={()=>setModalVisible(false)}>
                    Cancel
                </Button>
            ]}
        >
          <div className="login-form">
            <Form
              className="loginFormSpacing"
              {...layout}
              name="basic"
              onFinish={onDetailSubmit}
            >
              {/* <Tooltip placement="rightBottom" color="red" title="required field"> */}
                <label>First Name</label>
              {/* </Tooltip> */}
              <Form.Item
                name="firstname"
                rules={[
                  {  required: true, message: "Please enter your first name!" },
                  { min: 2, message: "First name must be minimum 2 characters." },
                  {
                    validator(_, value ="") {
                      if (value.includes(" ")) {
                        return Promise.reject("No spaces allowed");
                      } else {
                        return Promise.resolve();
                      }
                    }
                  }
                ]}
              >
                <Input id="firstname" placeholder="First Name" />
              </Form.Item>

              {/* <Tooltip placement="rightBottom" color="red" title="required field"> */}
                <label>Last Name</label>
              {/* </Tooltip> */}
              <Form.Item
                name="lastname"
                rules={[
                  {  required: true, message: "Please enter your last name!" },
                  { min: 2, message: "Last name must be minimum 2 characters." },
                  // {
                    // validator(_, value ="") {
                    //   if (value.includes(" ")) {
                    //     return Promise.reject("No spaces allowed");
                    //   } else {
                    //     return Promise.resolve();
                    //   }
                    // }
                  // }
                ]}
              >
                <Input id="lastname" placeholder="Last Name"/>
              </Form.Item>
              <Form.Item className="sign-btn">
                <Button
                  type="primary"
                  htmlType="submit"
                  id="loginBtn"
                  className="signin-buttons"
                  disabled={loading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
    )
}
