import {
  HIDE_LOADING,
  LOGIN_SUCCESS,
  LOGIN_STARTED,
  LOGIN_FAILED,
  UPDATE_SUBSCRIPTION,
  SIGNUP_STARTED,
  SIGNUP_SUCCESS,
  SIGNUP_FAILED,
  GET_TOTAL_RECORDS_COUNT,
  VIEW_FILE_DETAILS,
  USER_PROFILE_FETCH_SUCCESS,
  USER_PROFILE_FETCH_FAILED,
  SIGNUP_NOTSTARTED,
  UPLOAD_STARTED,
  UPLOAD_SUCCESS,
  UPLOAD_FAILED,
  UPLOAD_NOTSTARTED,
  CHANGE_PASSWORD_STARTED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_NOTSTARTED,
  DELETE_DASHBOARD_NOTSTARTED,
  DELETE_DASHBOARD_SUCCESS,
  DELETE_DASHBOARD_STARTED,
  DELETE_DASHBOARD_FAILED,
  GET_USERS_STARTED,
  GET_USERS_SUCCESS,
  GET_USERS_FAILED,
  UPDATE_USER_STARTED,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  UPDATE_USER_NOTSTARTED,
  UPDATE_USER_ACTIVE_STATUS_STARTED,
  UPDATE_USER_ACTIVE_STATUS_SUCCESS,
  UPDATE_USER_ACTIVE_STATUS_FAILED,
  UPDATE_USER_ACTIVE_STATUS_NOTSTARTED,
  UPDATE_REDUX_ACTIVE_STATE,
  LOGIN_NOTSTARTED,
  IFRAME_LOAD_STARTED,
  IFRAME_LOAD_SUCCESS,
  IFRAME_LOAD_NOTSTARTED,
  BLOBS_LOAD_STARTED,
  BLOBS_LOAD_SUCCESS,
  BLOBS_LOAD_FAILED,
  UPDATE_USER_ASADMIN_STARTED,
  UPDATE_USER_ASADMIN_SUCCESS,
  UPDATE_USER_ASADMIN_FAILED,
  UPDATE_USER_ASADMIN_NOTSTARTED,
  UPDATE_REDUX_USER_SUBSCRIPTION,
  DELETE_CDT_DASHBOARD_NOTSTARTED,
  DELETE_CDT_DASHBOARD_SUCCESS,
  DELETE_CDT_DASHBOARD_STARTED,
  DELETE_CDT_DASHBOARD_FAILED,
  POST_PROCESS_BLOBS_LOAD_STARTED,
  POST_PROCESS_BLOBS_LOAD_SUCCESS,
  POST_PROCESS_BLOBS_LOAD_FAILED,
  CDT_ITEM_BLOBS_LOAD_STARTED,
  CDT_ITEM_BLOBS_LOAD_SUCCESS,
  CDT_ITEM_BLOBS_LOAD_FAILED,
  UPDATE_USER_CDT_ACCOUNT_STARTED,
  UPDATE_USER_CDT_ACCOUNT_SUCCESS,
  UPDATE_USER_CDT_ACCOUNT_FAILED,
  UPDATE_REDUX_CDT_ACCOUNT_STATE,
  CDT_USER_FILES_BLOBS_LOAD_STARTED,
  CDT_USER_FILES_BLOBS_LOAD_SUCCESS,
  CDT_USER_FILES_BLOBS_LOAD_FAILED,
  CDT_ITEM_OUTPUT_BLOBS_LOAD_STARTED,
  CDT_ITEM_OUTPUT_BLOBS_LOAD_SUCCESS,
  CDT_ITEM_OUTPUT_BLOBS_LOAD_FAILED,
  GET_CDT_TRANSACTIONS_STARTED,
  GET_CDT_TRANSACTIONS_SUCCESS,
  GET_CDT_TRANSACTIONS_FAILED,
  CDT_USER_FILES_BLOBS_LOAD__NOTSTARTED,
  GET_WEB_TRANSACTIONS_STARTED,
  GET_WEB_TRANSACTIONS_SUCCESS,
  GET_WEB_TRANSACTIONS_FAILED,
  GET_WEB_TRANSACTIONS_NOTSTARTED,
  GET_USER_TRANSACTIONS_STARTED,
  GET_USER_TRANSACTIONS_SUCCESS,
  GET_USER_TRANSACTIONS_FAILED,
  GET_USER_TRANSACTIONS_NOTSTARTED,
  PRE_PROCESS_API_CALL_STARTED,
  PRE_PROCESS_API_CALL_SUCCESS,
  PRE_PROCESS_API_CALL_FAILED,
  PRE_PROCESS_API_CALL_NOTSTARTED
} from "../types/types";

export enum loadState {
  NOTSTARTED,
  STARTED,
  SUCCESS,
  FAILED,
}

export interface IUploadedFileDetails {
  createdAt: string;
  fileLocation: string;
  id: number;
  imagePath: string;
  isProcessed: boolean;
  organization: string;
  processInitiated: boolean;
  processingType: string;
  updatedAt: string;
  uploadFinished: boolean;
  uploadStarted: boolean;
  uploadedFiles: string;
  userName: string;
  processStatus: string;
}

const DefaultAppState: IAppState = {
  showLoading: false,
  notification: {
    type: "",
    message: "",
    description: "",
    status: false,
  },
  roles: [],
  error: "",
  userData: {
    email: "",
    id: "",
    roles: [],
    username: "",
    subscription: "",
    organization: "",
    subscriptionType: 1,
    is_account_active: true,
    used_input_limit: "",
    used_output_limit: "",
    used_analysis_limit: "",
    createdAt: new Date(0),
    previousMonthDate: new Date(0),
  },
  loginLoadState: loadState.NOTSTARTED,
  apiError: false,
  fileDetails: {
    createdAt: "",
    fileLocation: "",
    id: 0,
    imagePath: "",
    isProcessed: false,
    organization: "",
    processInitiated: false,
    processingType: "",
    updatedAt: "",
    uploadFinished: false,
    uploadStarted: false,
    uploadedFiles: "",
    userName: "",
    processStatus: "In-Process",
  },
  signupLoadState: loadState.NOTSTARTED,
  changePasswordLoadState: loadState.NOTSTARTED,
  deleteDashboardLoadState: loadState.NOTSTARTED,
  manageUsersLoadState: loadState.NOTSTARTED,
  updateUserLoadState: loadState.NOTSTARTED,
  totalRecordsCount: 0,
  activeUpdateCounter: 0,
  users: [],
  uploadFileLoadState: loadState.NOTSTARTED,
  profileFetchLoadState: loadState.NOTSTARTED,
  iframeLoadState: loadState.NOTSTARTED,
  preprocessBlobLoadState: loadState.NOTSTARTED,
  preProcessBlobs: [],
  deleteCDTDashboardLoadState: loadState.NOTSTARTED,
  postprocessBlobLoadState: loadState.NOTSTARTED,
  itemCDTBlobsLoadState: loadState.NOTSTARTED,
  postProcessBlobs: [],
  itemCDTBlobs: [],
  usercdtFilesBlobLoadState: loadState.NOTSTARTED,
  userCdtFilesBlobs: [],
  itemCDTOUTPUTBlobsLoadState: loadState.NOTSTARTED,
  itemCDTOUTPUTBlobs: [],
  cdtTransactions: [],
  cdtTransactionsLoadState: loadState.NOTSTARTED,
  webTransactions: [],
  webTransactionsLoadState: loadState.NOTSTARTED,
  userTransactions: [],
  userTransactionsLoadState: loadState.NOTSTARTED,
  preProcess: [],
  preProcessApiLoadState: loadState.NOTSTARTED,
};

export interface IUserData {
  email: string;
  id: string;
  roles: string[];
  username: string;
  subscription: string;
  organization: string;
  subscriptionType: number;
  is_account_active: boolean;
  used_input_limit: string;
  used_output_limit: string;
  used_analysis_limit: string;
  createdAt: Date;
  previousMonthDate: Date;
  firstname?: any;
  lastname?: any;
}

export interface INotificationState {
  type: string;
  message: string;
  description: string;
  status: boolean;
}

export interface INavigationState { }

export interface ICdtTransactionsData {
  order_number: string;
  id: string;
  username: string;
  token: string;
  transaction_time: string;
  subscription_type: string;
  amount: string;
  currency: string;
  status: string;
  paid: string;
  created: string;
  UUid: string;
  current_period_start: string;
  current_period_end: string;
}

export interface IWebTransactionsData {
  order_number: string;
  id: string;
  username: string;
  token: string;
  transaction_time: string;
  subscription_type: string;
  amount: string;
  currency: string;
  status: string;
  paid: string;
  created: string;
  UUid: string;
  current_period_start: string;
  current_period_end: string;
}

export interface IAppState {
  showLoading: Boolean;
  notification: INotificationState;
  roles: string[];
  error: string;
  userData: IUserData;
  loginLoadState: loadState;
  apiError: boolean;
  fileDetails: IUploadedFileDetails;
  signupLoadState: loadState;
  changePasswordLoadState: loadState;
  totalRecordsCount: number;
  deleteDashboardLoadState: loadState;
  manageUsersLoadState: loadState;
  updateUserLoadState: loadState;
  activeUpdateCounter: number;
  users: IUserData[];
  uploadFileLoadState: loadState;
  profileFetchLoadState: loadState;
  iframeLoadState: loadState;
  preprocessBlobLoadState: loadState,
  preProcessBlobs: string[],
  deleteCDTDashboardLoadState: loadState;
  postprocessBlobLoadState: loadState,
  postProcessBlobs: string[],
  itemCDTBlobs: string[],
  itemCDTBlobsLoadState: loadState,
  usercdtFilesBlobLoadState: loadState,
  userCdtFilesBlobs: string[],
  itemCDTOUTPUTBlobsLoadState: loadState,
  itemCDTOUTPUTBlobs: string[],
  cdtTransactions: ICdtTransactionsData[];
  cdtTransactionsLoadState: loadState;
  webTransactions: IWebTransactionsData[];
  webTransactionsLoadState: loadState;
  userTransactions: IWebTransactionsData[];
  userTransactionsLoadState: loadState;
  preProcessApiLoadState: loadState,
  preProcess: string[],
}

export default (
  state: IAppState = DefaultAppState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case LOGIN_STARTED:
      return {
        ...state,
        loginLoadState: loadState.STARTED,
        apiError: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        loginLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };
    case LOGIN_NOTSTARTED:
      return {
        ...state,
        loginLoadState: loadState.NOTSTARTED,
      };
    case USER_PROFILE_FETCH_SUCCESS:
      return {
        ...state,
        userData: {
          ...action.payload,
        },
        profileFetchLoadState: loadState.SUCCESS,
      };
    case USER_PROFILE_FETCH_FAILED:
      return {
        ...state,
        apiError: true,
        error: action.payload,
        profileFetchLoadState: loadState.FAILED,
      };
    case UPDATE_SUBSCRIPTION:
      return {
        ...state,
        userData: { ...state.userData, subscription: action.payload },
        apiError: false,
      };
    case HIDE_LOADING:
      return {
        ...state,
        showLoading: action.payload,
      };
    case SIGNUP_STARTED:
      return {
        ...state,
        signupLoadState: loadState.STARTED,
        apiError: false,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        signupLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case SIGNUP_FAILED:
      return {
        ...state,
        signupLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };
    case SIGNUP_NOTSTARTED:
      return {
        ...state,
        signupLoadState: loadState.NOTSTARTED,
      };
    case GET_TOTAL_RECORDS_COUNT:
      return {
        ...state,
        totalRecordsCount: action.payload,
      };
    case VIEW_FILE_DETAILS:
      return {
        ...state,
        fileDetails: action.payload,
      };
    case UPLOAD_STARTED:
      return {
        ...state,
        uploadFileLoadState: loadState.STARTED,
        apiError: false,
      };
    case UPLOAD_SUCCESS:
      return {
        ...state,
        uploadFileLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case UPLOAD_FAILED:
      return {
        ...state,
        uploadFileLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };
    case UPLOAD_NOTSTARTED:
      return {
        ...state,
        uploadFileLoadState: loadState.NOTSTARTED,
        apiError: true,
        error: action.payload,
      };
    case CHANGE_PASSWORD_STARTED:
      return {
        ...state,
        changePasswordLoadState: loadState.STARTED,
        apiError: false,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        changePasswordLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };
    case CHANGE_PASSWORD_NOTSTARTED:
      return {
        ...state,
        changePasswordLoadState: loadState.NOTSTARTED,
      };
    case DELETE_DASHBOARD_STARTED:
      return {
        ...state,
        deleteDashboardLoadState: loadState.STARTED,
        apiError: false,
      };
    case DELETE_DASHBOARD_SUCCESS:
      return {
        ...state,
        deleteDashboardLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case DELETE_DASHBOARD_FAILED:
      return {
        ...state,
        deleteDashboardLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };
    case DELETE_DASHBOARD_NOTSTARTED:
      return {
        ...state,
        deleteDashboardLoadState: loadState.NOTSTARTED,
      };
    case GET_USERS_STARTED:
      return {
        ...state,
        manageUsersLoadState: loadState.STARTED,
        apiError: false,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: [...action.payload],
        manageUsersLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case GET_USERS_FAILED:
      return {
        ...state,
        manageUsersLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };
    case UPDATE_USER_STARTED:
      return {
        ...state,
        updateUserLoadState: loadState.STARTED,
        apiError: false,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case UPDATE_USER_FAILED:
      return {
        ...state,
        updateUserLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };
    case UPDATE_USER_NOTSTARTED:
      return {
        ...state,
        updateUserLoadState: loadState.NOTSTARTED,
      };
    case UPDATE_USER_ACTIVE_STATUS_STARTED:
      return {
        ...state,
        updateUserLoadState: loadState.STARTED,
        apiError: false,
      };
    case UPDATE_USER_ACTIVE_STATUS_SUCCESS:
      return {
        ...state,
        updateUserLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case UPDATE_USER_ACTIVE_STATUS_FAILED:
      return {
        ...state,
        updateUserLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };
    case UPDATE_USER_ACTIVE_STATUS_NOTSTARTED:
      return {
        ...state,
        updateUserLoadState: loadState.NOTSTARTED,
      };
    case UPDATE_USER_ASADMIN_STARTED:
      return {
        ...state,
        updateUserLoadState: loadState.STARTED,
        apiError: false,
      };
    case UPDATE_USER_ASADMIN_SUCCESS:
      return {
        ...state,
        updateUserLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case UPDATE_USER_ASADMIN_FAILED:
      return {
        ...state,
        updateUserLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };
    case UPDATE_USER_ASADMIN_NOTSTARTED:
      return {
        ...state,
        updateUserLoadState: loadState.NOTSTARTED,
      };
    case UPDATE_REDUX_ACTIVE_STATE:
      const updatedUsers: any = state.users.map((item) => {
        if (item.id === action.payload.id)
          return {
            ...item,
            is_account_active: action.payload.is_account_active,
          };
        else return { ...item };
      });

      return {
        ...state,
        users: [...updatedUsers],
        activeUpdateCounter: state.activeUpdateCounter + 1,
      };
    case UPDATE_REDUX_USER_SUBSCRIPTION:
      const updatedUsers2: any = state.users.map((item) => {
        if (item.id === action.payload.id)
          return {
            ...item,
            subscription: action.payload.subscription,
            subscription_type: action.payload.subscription_type,
          };
        else return { ...item };
      });

      return {
        ...state,
        users: [...updatedUsers2],
        activeUpdateCounter: state.activeUpdateCounter + 1,
      };

    case IFRAME_LOAD_STARTED:
      return {
        ...state,
        iframeLoadState: loadState.STARTED,
        apiError: false,
      };
    case IFRAME_LOAD_SUCCESS:
      return {
        ...state,
        iframeLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case IFRAME_LOAD_NOTSTARTED:
      return {
        ...state,
        iframeLoadState: loadState.NOTSTARTED,
      };
    case BLOBS_LOAD_STARTED:
      return {
        ...state,
        preprocessBlobLoadState: loadState.STARTED,
        apiError: false,
      };
    case BLOBS_LOAD_SUCCESS:
      return {
        ...state,
        preProcessBlobs: [...action.payload],
        preprocessBlobLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case BLOBS_LOAD_FAILED:
      return {
        ...state,
        preprocessBlobLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };
    case DELETE_CDT_DASHBOARD_STARTED:
      return {
        ...state,
        deleteCDTDashboardLoadState: loadState.STARTED,
        apiError: false,
      };
    case DELETE_CDT_DASHBOARD_SUCCESS:
      return {
        ...state,
        deleteCDTDashboardLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case DELETE_CDT_DASHBOARD_FAILED:
      return {
        ...state,
        deleteCDTDashboardLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };
    case DELETE_CDT_DASHBOARD_NOTSTARTED:
      return {
        ...state,
        deleteCDTDashboardLoadState: loadState.NOTSTARTED,
      };
    case POST_PROCESS_BLOBS_LOAD_STARTED:
      return {
        ...state,
        postprocessBlobLoadState: loadState.STARTED,
        apiError: false,
      };
    case POST_PROCESS_BLOBS_LOAD_SUCCESS:
      return {
        ...state,
        postProcessBlobs: [...action.payload],
        postprocessBlobLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case POST_PROCESS_BLOBS_LOAD_FAILED:
      return {
        ...state,
        postprocessBlobLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };
    case CDT_ITEM_BLOBS_LOAD_STARTED:
      return {
        ...state,
        itemCDTBlobsLoadState: loadState.STARTED,
        apiError: false,
      };
    case CDT_ITEM_BLOBS_LOAD_SUCCESS:
      return {
        ...state,
        itemCDTBlobs: [...action.payload],
        itemCDTBlobsLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case CDT_ITEM_BLOBS_LOAD_FAILED:
      return {
        ...state,
        itemCDTBlobsLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };
    case UPDATE_USER_CDT_ACCOUNT_STARTED:
      return {
        ...state,
        updateUserLoadState: loadState.STARTED,
        apiError: false,
      };
    case UPDATE_USER_CDT_ACCOUNT_SUCCESS:
      return {
        ...state,
        updateUserLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case UPDATE_USER_CDT_ACCOUNT_FAILED:
      return {
        ...state,
        updateUserLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };
    case UPDATE_REDUX_CDT_ACCOUNT_STATE:
      const updatedUsers3: any = state.users.map((item) => {
        if (item.id === action.payload.id)
          return {
            ...item,
            is_cdt_openmax: action.payload.is_cdt_openmax,
          };
        else return { ...item };
      });

      return {
        ...state,
        users: [...updatedUsers3],
        activeUpdateCounter: state.activeUpdateCounter + 1,
      };

    case CDT_USER_FILES_BLOBS_LOAD_STARTED:
      return {
        ...state,
        usercdtFilesBlobLoadState: loadState.STARTED,
        apiError: false,
      };
    case CDT_USER_FILES_BLOBS_LOAD_SUCCESS:
      const filteredcdtfiles1: any = action.payload.filter(
        (item: any) =>
          !item.includes(".csv") &&
          !item.includes(".zip") &&
          !item.includes("/cdtoutputFolderCretionFile")
      );

      return {
        ...state,
        userCdtFilesBlobs: [...filteredcdtfiles1],
        usercdtFilesBlobLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case CDT_USER_FILES_BLOBS_LOAD_FAILED:
      return {
        ...state,
        usercdtFilesBlobLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };
    case CDT_USER_FILES_BLOBS_LOAD__NOTSTARTED:
      return {
        ...state,
        usercdtFilesBlobLoadState: loadState.NOTSTARTED,
      };

    case CDT_ITEM_OUTPUT_BLOBS_LOAD_STARTED:
      return {
        ...state,
        itemCDTOUTPUTBlobsLoadState: loadState.STARTED,
        apiError: false,
      };
    case CDT_ITEM_OUTPUT_BLOBS_LOAD_SUCCESS:
      return {
        ...state,
        itemCDTOUTPUTBlobs: action.payload,
        itemCDTOUTPUTBlobsLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case CDT_ITEM_OUTPUT_BLOBS_LOAD_FAILED:
      return {
        ...state,
        itemCDTOUTPUTBlobsLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };
    case GET_CDT_TRANSACTIONS_STARTED:
      return {
        ...state,
        cdtTransactionsLoadState: loadState.STARTED,
        apiError: false,
      };
    case GET_CDT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        cdtTransactions: [...action.payload],
        cdtTransactionsLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case GET_CDT_TRANSACTIONS_FAILED:
      return {
        ...state,
        cdtTransactionsLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };
    case GET_WEB_TRANSACTIONS_STARTED:
      return {
        ...state,
        webTransactionsLoadState: loadState.STARTED,
        apiError: false,
      };
    case GET_WEB_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        webTransactions: [...action.payload],
        webTransactionsLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case GET_WEB_TRANSACTIONS_FAILED:
      return {
        ...state,
        webTransactionsLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };
    case GET_WEB_TRANSACTIONS_NOTSTARTED:
      return {
        ...state,
        webTransactionsLoadState: loadState.NOTSTARTED,
      };
    case GET_USER_TRANSACTIONS_STARTED:
      return {
        ...state,
        userTransactionsLoadState: loadState.STARTED,
        apiError: false,
      };
    case GET_USER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        userTransactions: [...action.payload],
        userTransactionsLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case GET_USER_TRANSACTIONS_FAILED:
      return {
        ...state,
        userTransactionsLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };
    case GET_USER_TRANSACTIONS_NOTSTARTED:
      return {
        ...state,
        userTransactionsLoadState: loadState.NOTSTARTED,
      };
    case PRE_PROCESS_API_CALL_STARTED:
      return {
        ...state,
        preProcessApiLoadState: loadState.STARTED,
        apiError: false,
      };
    case PRE_PROCESS_API_CALL_SUCCESS:
      return {
        ...state,
        preProcess: [...action.payload],
        preProcessApiLoadState: loadState.SUCCESS,
        apiError: false,
      };
    case PRE_PROCESS_API_CALL_FAILED:
      return {
        ...state,
        preProcessApiLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };
    case PRE_PROCESS_API_CALL_NOTSTARTED:
      return {
        ...state,
        preProcessApiLoadState: loadState.NOTSTARTED,
      };
    default:
      return state;
  }
};
